import React from 'react'
import "../pages/panama.css"
import "../pages/index.css"
import bristol from "/src/images/hotel_bristol.jpg"
import casco from "/src/images/hotel_casco.jpg"
import marriott from "/src/images/hotel_marriot.jpg"
import residencein from "/src/images/hotel_residencein.jpeg"
import goldentower from "/src/images/hotel_golden.jpeg"
import sofitel from "/src/images/hotel_sofitel.jpg"
import flores1 from "/src/images/Flores2.png"
import flores2 from "/src/images/Flores1.png"
import Navigation from "../components/navbar"

function HotelsPage() {
  return (
    <>
      <Navigation />
      <title>GABELLA - Panama</title>
      <div className='container'>
        <img src={flores2} className="hotels__flower2" />
        <img src={flores1} className="hotels__flower1" />
        <h2 className='lowercase t-center hotels__title'>Hoteles</h2>
        <div className='hotels__grid'>
          <a href='https://www.bristolpanama.com/' className='hotels__grid-clm'>
            <img src={bristol} className='hotels__grid-img' />
            <div className='hotels__grid-text'>
              <p className='t-sm'>Bristol Panamá</p>
            </div>
          </a>
          <a href='https://www.marriott.com/en-us/hotels/ptymj-jw-marriott-panama/overview/?scid=310b23b3-c9d3-4fd9-a81c-b2dbecaeacf2&gclid=CjwKCAjwzNOaBhAcEiwAD7Tb6GJN1Mrt2xW7ESHwQx2MpNfAbRLwF4HWOYgDUDzqSzb-7vTKlhhoqRoCqzMQAvD_BwE&gclsrc=aw.ds' className='hotels__grid-clm'>
            <img src={marriott} className='hotels__grid-img' />
            <div className='hotels__grid-text'>
              <p className='t-sm'>JW Marriott</p>
            </div>
          </a>
          <a href='https://www.hyatt.com/en-US/hotel/panama/hotel-la-compania/ptyub?src=adm_sem_crp_chico_crp_ppc_LAC-Panama-None-PanamaCity-UB-PTYUB_google_Evergreen2022_e_hotel%20la%20compa%C3%B1ia%20panama&gclid=CjwKCAjwzNOaBhAcEiwAD7Tb6InKpxBTcyrenZlX9XsUSaBTiZwWNdKqe1dna6OjDeTgkqU1i7-yuxoCPdgQAvD_BwE' className='hotels__grid-clm'>
            <img src={casco} className='hotels__grid-img' />
            <div className='hotels__grid-text'>
              <p className='t-sm'>La Compañia</p>
            </div>
          </a>
          <a href='https://all.accor.com/hotel/9156/index.en.shtml' className='hotels__grid-clm'>
            <img src={sofitel} className='hotels__grid-img' />
            <div className='hotels__grid-text'>
              <p className='t-sm'>Sofitel</p>
            </div>
          </a>
          <a href='https://www.marriott.com/en-us/hotels/ptyri-residence-inn-panama-city/overview/?scid=e1226526-757d-4865-8c54-5d1c0b7c77a2&gclid=CjwKCAjwzNOaBhAcEiwAD7Tb6OTJO1oAyTDvXnE7XH-tiJ3aSdRyBEJ9oRaucaIiLE5WRZ8YgFIPZRoCThIQAvD_BwE&gclsrc=aw.ds' className='hotels__grid-clm'>
            <img src={residencein} className='hotels__grid-img' />
            <div className='hotels__grid-text'>
              <p className='t-sm'>Ressidence In by Marriot</p>
            </div>
          </a>
          <a href='https://lasamericasgoldentower.com/' className='hotels__grid-clm'>
            <img src={goldentower} className='hotels__grid-img' />
            <div className='hotels__grid-text'>
              <p className='t-sm'>Las Américas Golden Tower</p>
            </div>
          </a>
        </div>
        <div className='hotels__todo'>
          <h2 className='lowercase'>Things to do in Panama</h2>
          <div className='two__columns'>
            <div className='two__colums-title'>
              <p className='t-up t-m two__colums-title-p f-400'>Brunch</p>
            </div>
            <ul className='two__columns-ul'>
              <li className='t-m'><a href="https://www.instagram.com/amtradehotel/"><p>- <span class="link">American Trade Hotel</span></p></a></li>
              <li className='t-m'><a href="https://www.instagram.com/chevrepanama/"><p>- <span class="link">Chevre</span></p></a></li>
              <li className='t-m'><a href="https://www.instagram.com/bruttitorest/"><p>- <span class="link">Brutito</span></p></a></li>
            </ul>
          </div>
          <div className='two__columns'>
            <div className='two__colums-title'>
              <p className='t-up t-m two__colums-title-p f-400'>Lunch</p>
            </div>
            <ul className='two__columns-ul'>
              <li className='t-m '><a href="https://www.instagram.com/fondaloquehay/"><p>- <span class='link'>Fonda Lo Qué Hay</span></p></a></li>
              <li className='t-m '><a href="https://www.instagram.com/maitopanama/"><p>- <span class="link">Maito</span></p></a></li>
              <li className='t-m '><a href="https://www.instagram.com/cantinadeltigre/"><p>- <span class="link">Cantina del Tigre</span></p></a></li>
              <li className='t-m'><a href="https://www.instagram.com/bruttorest/" ><p>- <span class="link">Brutto</span></p></a></li>
              <li className='t-m'><a href="https://www.instagram.com/casastizzoli/" ><p>- <span class="link">Casa Stizzoli</span></p></a></li>
              <li className='t-m'><a href="https://www.instagram.com/marketpanama/"><p>- <span class="link">Market</span></p></a></li>
              <li className='t-m'><a href="https://www.instagram.com/hotellacompania/"><p>- <span class="link">La Compañia</span></p></a></li>
              <li className='t-m'><a href="https://www.instagram.com/sofitellegendcascoviejo/" ><p>- <span class="link">Sofitel</span></p></a></li>
              <li className='t-m'><a href="https://www.instagram.com/cuquitacookita/"><p>- <span class="link">Cuquita Cookita</span></p></a></li>
              <li className='t-m'><a href="https://www.instagram.com/salva_rest/"><p>- <span class="link">Salva Restaurante</span></p></a></li>
            </ul>
          </div>
          <div className='two__columns'>
            <div className='two__colums-title'>
              <p className='t-up t-m two__colums-title-p f-400'>Dinner</p>
            </div>
            <ul className='two__columns-ul'>
              <li className='t-m'><a href="https://www.instagram.com/makotopanama/" ><p>- <span class="link">Makoto</span></p></a></li>
              <li className='t-m'><a href="https://www.instagram.com/losalocos/" ><p>- <span class="link">Los años locos</span></p></a></li>
              <li className='t-m'><a href="https://www.instagram.com/vinotecapty/" ><p>- <span class="link">Vinoteca</span></p></a></li>
              <li className='t-m '><a href="https://www.instagram.com/achacde/" ><p>- <span class="link">Acha</span></p></a></li>
              <li className='t-m'><a href="https://www.instagram.com/filomenarest/" ><p>- <span class="link">Filomena</span></p></a></li>
              <li className='t-m'><a href="https://www.instagram.com/lima_panama/" ><p>- <span class="link">Lima Nikkei</span></p></a></li>
            </ul>
          </div>
          <div className='two__columns'>
            <div className='two__colums-title'>
              <p className='t-up t-m two__colums-title-p f-400'>Drinks & dinner</p>
            </div>
            <ul className='two__columns-ul'>
              <li className='t-m'><a href="https://www.instagram.com/azaharpty/"><p>- <span class="link">Azahar</span></p></a></li>
              <li className='t-m'><a href="https://www.instagram.com/bruttorest/"><p>- <span class="link">Brutto</span></p></a></li>
              <li className='t-m '><a href="https://www.instagram.com/misticorest/?hl=en"><p>- <span class="link">Místico</span></p></a></li>
              <li className='t-m'><a href="https://www.instagram.com/intimorestaurante/?hl=en"><p>- <span class="link">Íntimo</span></p></a></li>
              <li className='t-m'><a href="https://www.instagram.com/salvajecostadeleste/?hl=en"><p>- <span class="link">Salvaje </span></p></a></li>
            </ul>
          </div>
          <div className='two__columns'>
            <div className='two__colums-title'>
              <p className='t-up t-m two__colums-title-p f-400'>Bares</p>
            </div>
            <ul className='two__columns-ul'>
              <li className='t-m'><a href="https://www.instagram.com/makotopanama/"><p>- <span class="link">Makoto Speakeasy </span></p></a></li>
              <li className='t-m'><a href="https://www.instagram.com/lamilagrosapanama/?hl=en"><p>- <span class="link">La Milagrosa</span></p></a></li>
              <li className='t-m'><a href="https://www.instagram.com/mangle.bar/?hl=en"><p>- <span class="link">Mangle</span></p></a></li>
            </ul>
          </div>
          <div className='two__columns'>
            <div className='two__colums-title'>
              <p className='t-up t-m two__colums-title-p f-400'>Rooftops</p>
            </div>
            <ul className='two__columns-ul'>
              <li className='t-m'><a href="https://www.instagram.com/casacasco/?hl=en"><p>- <span class="link">Casa casco</span></p></a></li>
              <li className='t-m'><a href="https://www.instagram.com/lazoteapanama/?hl=en" ><p>- <span class="link">La Azotea</span></p></a></li>
              <li className='t-m'><a href="https://www.instagram.com/hotellacompania/"><p>- <span class="link">La Compañia</span></p></a></li>
              <li className='t-m'><a href="https://www.instagram.com/amanopanama/?hl=en" ><p>- <span class="link">Amano</span></p></a></li>
            </ul>
          </div>
          <div className='two__columns'>
            <div className='two__colums-title'>
              <p className='t-up t-m two__colums-title-p f-400'>Actividades</p>
            </div>
            <ul className='two__columns-ul'>
              <li className='t-m'><a href="http://www.panarail.com/"><p>- <span class="link">Panama Canal Railroad Tour</span></p></a></li>
              <li className='t-m'><a href="https://www.instagram.com/biomuseo/?hl=en"><p>- <span class="link">Biomuseo</span></p></a></li>
              <li className='t-m'><a href="https://pancanal.com/es/"><p>- <span class="link">Panama Canal</span></p></a></li>
              <li className='t-m'><p>- <span class="link">Cerro Ancon Hike</span></p></li>
            </ul>
          </div>
        </div>
        <div className='salon__box'>
          <div className='two__columns'>
            <div className='two__colums-title' id="salon__box-title">
              <p className='t-up t-m two__colums-title-p f-400'>Salón de belleza</p>
            </div>
            <ul className='two__columns-ul'>
              <li className='t-m'><a href="https://www.instagram.com/mirandamakeupart/?hl=en"><p>- <span class="link">Miranda Makeup Art</span></p></a></li>
              <li className='t-m'><a href="https://www.instagram.com/hairarchy_salon/?hl=en"><p>- <span class="link">Hairarchy Salon</span></p></a></li>
              <li className='t-m'><a href="https://www.instagram.com/nicola.mmua/?hl=en"><p>- <span class="link">Nicola MUA</span></p></a></li>
              <li className='t-m'><a href="https://www.instagram.com/andreamakeups_/"><p>- <span class="link">Andrea Garcia</span></p></a></li>
              <li className='t-m'><a href="https://www.instagram.com/fuzion_salon/?hl=en"><p>- <span class="link">Fuzion Salon</span></p></a></li>
              <li className='t-m'><a href="https://www.instagram.com/blendinghairstudio/?hl=en"><p>- <span class="link">Blending Salon</span></p></a></li>
            </ul>
          </div>
        </div>
      </div>
      <div className='footer'>
        <p className='text__white t-m'>#GABELLA <spa className='text__pink  t-title'>07.01.23</spa> PANAMÁ</p>
      </div>
    </>
  )
}

export default HotelsPage
